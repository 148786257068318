/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Buffer } from 'buffer';

import { http } from 'viem';
import { createConfig, fallback } from 'wagmi';

import { CHAINS, rpcUrlsByChain } from './chains';

if (!window.Buffer) {
  window.Buffer = Buffer;
}

const PROVIDER_BATCH_TIME = 200;
const PROVIDER_MAX_BATCH = 25;
const PROVIDER_RETRY_COUNT = 1;

export const wagmiConfig = createConfig({
  chains: CHAINS,
  multiInjectedProviderDiscovery: false,
  transports: CHAINS.reduce((acc, chain) => {
    const urls = rpcUrlsByChain[chain.id];

    acc[chain.id] = fallback(
      urls.map((url) =>
        http(url, {
          batch: {
            wait: PROVIDER_BATCH_TIME,
            batchSize: PROVIDER_MAX_BATCH,
          },
          retryCount: PROVIDER_RETRY_COUNT,
        }),
      ),
    );

    return acc;
  }, {} as Record<number, ReturnType<typeof fallback>>),
});
