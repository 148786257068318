export const mapPriceToRound: Record<string, number> = {
  '0.2': 1,
  '0.22': 2,
  '0.23': 3,
  '0.24': 4,
  '0.25': 5,
  '0.26': 6,
  '0.27': 7,
  '0.28': 8,
  '0.29': 9,
  '0.30': 910,
};

/**
 * CRAT-370
 *
 * 30.04.2025 22:59:59 UTC or 30.04.2025 23:59:59 GMT+1
 */
export const INITIAL_EXCHANGE_OFFERING_END_TIME = Date.UTC(2025, 3, 30, 22, 59, 59);
