import React from 'react';
import { Box, Button, Divider, MenuItem, SelectProps, Stack, TextFieldProps, Typography } from '@mui/material';
import { useWagmiAuth } from 'app/providers';
import { InputWithSelect, UsdcLogo, UsdtLogo, useBreakpoints } from 'shared';

type BuyInputProps = {
  onMaxClick: VoidFunction;
  onCurrencyChange: SelectProps['onChange'];
  currencyValue: string;
  stableBalance: string;
  isError?: boolean;
} & TextFieldProps;

const COINS = [
  {
    name: 'USDT',
    image: UsdtLogo,
    value: 'USDT',
  },
  {
    name: 'USDC',
    image: UsdcLogo,
    value: 'USDC',
  },
];

type Coin = (typeof COINS)[number];

const COINS_MAP = COINS.reduce((acc, coin) => {
  acc[coin.value] = coin;

  return acc;
}, {} as Record<Coin['value'], Coin>);

const OPTIONS = COINS.map((coin) => (
  <MenuItem value={coin.value} key={coin.value}>
    <Box
      component="img"
      width={20}
      height={20}
      src={coin.image}
      sx={{
        mr: 1,
      }}
    />
    {coin.name}
  </MenuItem>
));

export const BuyInput = React.forwardRef<any, BuyInputProps>(
  ({ onCurrencyChange, onMaxClick, isError, currencyValue, stableBalance, ...inputProps }, ref) => {
    const { isDownSmLayout } = useBreakpoints();
    const { state: authState } = useWagmiAuth();

    return (
      <InputWithSelect
        selectProps={{
          onChange: onCurrencyChange,
          value: currencyValue,
          options: OPTIONS,
          before: authState.isAuthorized && (
            <>
              <Button onClick={onMaxClick} color="secondary">
                Max
              </Button>
              <Divider orientation="vertical" sx={{ height: 'auto' }} />
            </>
          ),
          renderValue: (val) => {
            const coin = COINS_MAP[val as keyof typeof COINS_MAP];

            if (!coin) {
              return null;
            }

            return (
              <Stack direction="row">
                <Box component="img" width={20} height={20} src={coin?.image} sx={{ mr: 1 }} />
                {isDownSmLayout ? null : coin?.name}
              </Stack>
            );
          },
        }}
        inputProps={{
          ...inputProps,
          ref,
          placeholder: '0.00',
          color: isError ? 'error' : 'primary',
          helperText: authState.isAuthorized && (
            <Stack direction="row" alignItems="center" justifyContent="flex-end" component="span">
              <Typography component="span" fontSize={15} variant="h6" className="secondary" sx={{ textAlign: 'right' }}>
                {currencyValue} Balance:{' '}
                <Typography variant="inherit" component="span">
                  {stableBalance} {currencyValue}
                </Typography>
              </Typography>
            </Stack>
          ),
        }}
      />
    );
  },
);
