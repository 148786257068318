import { InputAdornment, inputBaseClasses, Select, SelectProps, Stack, TextField, TextFieldProps } from '@mui/material';
import { COLOR_WH } from 'shared/config';

interface InputWithSelectProps {
  inputProps?: TextFieldProps;
  selectProps?: SelectProps & { options?: React.ReactNode; before?: React.ReactNode };
}

export const InputWithSelect = ({ inputProps = {}, selectProps = {} }: InputWithSelectProps) => {
  const { options, before, ...restSelectProps } = selectProps;

  return (
    <TextField
      {...inputProps}
      sx={{
        [`& > .${inputBaseClasses.root}`]: {
          py: '5px',
          height: 56,

          [`& > .${inputBaseClasses.input}::placeholder`]: {
            fontSize: '20px',
            color: COLOR_WH,
            opacity: 1,
          },

          [`& > .${inputBaseClasses.input}`]: {
            lineHeight: '28px',
            fontSize: 20,
          },
        },

        ...inputProps?.sx,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Stack direction="row" spacing={2}>
              {before}
              <Select
                {...restSelectProps}
                MenuProps={{ sx: { mt: 2 } }}
                sx={{
                  width: { sm: 120, xs: 50 },
                  ...restSelectProps?.sx,
                }}
              >
                {options}
              </Select>
            </Stack>
          </InputAdornment>
        ),
      }}
    />
  );
};
