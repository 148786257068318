import { wagmiConfig } from 'app/config/wallet';
import { IS_PRODUCTION } from 'shared/config/env';
import { getContract } from 'viem';

import { ERC_20_ABI } from '../abi';

const address = {
  testnet: '0x66fd91C06C4adB7c411324BC54d4AF89a6fE585a',
  mainnet: '0x55d398326f99059fF775485246999027B3197955',
} as const;

export const usdtContract = getContract({
  abi: ERC_20_ABI,
  address: IS_PRODUCTION ? address.mainnet : address.testnet,
  client: wagmiConfig.getClient(),
});
