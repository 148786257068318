import { IconButton, Stack, styled, Typography } from '@mui/material';
import { crowdsaleModel } from 'entities/crowdsale';
import { COLOR_STROKE, COLOR_WH, FontWeights, fromDecimals, Icons, useShallowSelector } from 'shared';

import { useModalState } from './provider';

const CloseButton = styled(IconButton)(({ theme }) => ({
  background: 'none',
  marginLeft: 'auto',
  width: 48,
  height: 48,
  color: COLOR_WH,
  border: `1px solid ${COLOR_STROKE}`,
  borderRadius: '50%',
  svg: {
    width: 14,
    height: 14,
  },
  '&:hover': {
    background: 'none',
  },
}));

const Title = () => {
  const price = useShallowSelector(crowdsaleModel.selectors.getProp('cratPrice'));

  return (
    <Typography variant="h4" fontWeight={FontWeights.SemiBold}>
      Limited-Time Deal Buy CratD2C-Pre Token for ${fromDecimals(price)} and receive an equal amount of CRAT Native
      Coins - 100% bonus match!
    </Typography>
  );
};

export const Header = () => {
  const { tab, onClose } = useModalState();

  return (
    <Stack direction="row" justifyContent="space-between" gap={0.5}>
      {tab === 'form' && <Title />}
      <CloseButton onClick={onClose}>
        <Icons.Close />
      </CloseButton>
    </Stack>
  );
};
