// eslint-disable-next-line import/no-extraneous-dependencies
import { bsc, bscTestnet, Chain } from '@wagmi/core/chains';
import { IS_PRODUCTION } from 'shared/config/env';

const SUPPORTED_CHAINS_MAINNET = [bsc] as const;
const SUPPORTED_CHAINS_TESTNET = [bscTestnet] as const;

export const CHAINS: readonly [Chain, ...Chain[]] = IS_PRODUCTION ? SUPPORTED_CHAINS_MAINNET : SUPPORTED_CHAINS_TESTNET;

export const rpcUrlsByChain = {
  [bsc.id]: [
    'https://bsc-dataseed1.binance.org/',
    'https://bsc-dataseed2.binance.org/',
    'https://bsc-dataseed3.binance.org/',
    'https://rpc.ankr.com/bsc',
  ],
  [bscTestnet.id]: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
} as Record<number, string[]>;
