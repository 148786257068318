/* eslint-disable consistent-return */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';
import { useWagmiAuth } from 'app/providers';
import BigNumber from 'bignumber.js';
import { crowdsaleModel } from 'entities/crowdsale';
import { userModel } from 'entities/user';
import { calculateTierBonus } from 'pages/home/ui/crowdsale/lib';
import {
  accurateToFixed,
  checkIsAddress,
  fromDecimals,
  logger,
  scrollToElement,
  smartRound,
  toDecimals,
  useAppDispatch,
  useShallowSelector,
  useValidateInputField,
  ValidationTypes,
  wagmiContracts,
  ZERO_ADDRESS,
} from 'shared';
import { useAccount } from 'wagmi';

import { FormData, useBuyForm } from './useBuyForm';

const STABLECOINS_MAP = {
  USDT: wagmiContracts.contracts.USDT.address,
  USDC: wagmiContracts.contracts.USDC.address,
};

export const useBuyCoins = () => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const { chain } = useAccount();

  const [value, onChangeStableAmount, onChangeStableAmountOrigin] = useValidateInputField({
    initialState: '',
    type: ValidationTypes.number,
    minValue: 0,
    decimals: 6,
  });
  const [currency, setCurrency] = useState<'USDT' | 'USDC'>('USDT');

  const { state: authState } = useWagmiAuth();
  const balances = useShallowSelector(userModel.selectors.selectUserStableBalances);
  const referralFather = useShallowSelector(userModel.selectors.getProp('referralFather'));

  const tier = useShallowSelector(userModel.selectors.getProp('tier'));
  const totalSpend = useShallowSelector(userModel.selectors.getProp('totalSpend'));
  const cratPriceDecimal = useShallowSelector(crowdsaleModel.selectors.getProp('cratPrice'));
  const availableAmountDecimal = useShallowSelector(crowdsaleModel.selectors.getProp('cratAvailableAmount'));
  const buyData = useShallowSelector(crowdsaleModel.selectors.getProp('currentBuyData'));

  const stableAmount = String(parseFloat(smartRound(+fromDecimals(balances[currency]), 6)));
  const cratPrice = fromDecimals(cratPriceDecimal);
  const availableAmount = smartRound(+availableAmountDecimal);

  const userReferral = checkIsAddress(referralFather) && referralFather !== authState.address && referralFather;

  const receiveAmount = useMemo(() => {
    if (!value.length) {
      return '0';
    }

    const decimalValue = toDecimals(value);
    const totalValue = new BigNumber(decimalValue).plus(totalSpend);
    const nextTierBonus = calculateTierBonus(totalValue.toFixed(), cratPrice, Number(tier));

    return accurateToFixed(new BigNumber(decimalValue).dividedBy(cratPriceDecimal).plus(nextTierBonus).toNumber(), 6);
  }, [value, totalSpend, cratPrice, tier, cratPriceDecimal]);

  const {
    control,
    setValue,
    resetField,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    register,
  } = useBuyForm({ stableTokenBalance: stableAmount, maxValue: availableAmount });

  const amountError = errors.stablesAmount?.message;
  const referralError = errors.fatherAddress?.message;

  const errorMessage = amountError || referralError;

  useEffect(() => {
    const linkReferral =
      authState.isAuthorized && checkIsAddress(params.address || '') && params.address !== authState.address
        ? (params.address as string)
        : '';

    setValue('fatherAddress', userReferral || linkReferral);
  }, [userReferral, setValue, authState.isAuthorized, params.address, authState.address]);

  useEffect(() => {
    if (!buyData) {
      resetField('stablesAmount');
      onChangeStableAmountOrigin('');
    }
  }, [buyData]);

  const openConnectModal = useCallback(() => {
    scrollToElement('connect-button');

    // timeout prevents scroll bug after close popup
    setTimeout(() => {
      // dispatch(connectModalModel.actions.openModal({ anchorId: 'crowdsale' }));
    }, 800);
  }, []);

  const onCurrencyChange = useCallback((event: SelectChangeEvent<any>) => {
    setCurrency(event.target.value);
  }, []);

  const onReferralAddressChange = useCallback(
    (e: any) => {
      const match = /0x[a-fA-F0-9]{40}$/g.exec(e.target.value);

      if (!match) {
        return;
      }

      setValue('fatherAddress', match[0]);
    },
    [setValue],
  );

  const handleMaxClick = useCallback(async () => {
    if (Number(stableAmount) / Number(cratPrice) > Number(availableAmount)) {
      const maxStableValue = String(Number(availableAmount) * Number(cratPrice));

      setValue('stablesAmount', maxStableValue, { shouldValidate: true, shouldDirty: true });

      return onChangeStableAmountOrigin(maxStableValue);
    }

    setValue('stablesAmount', stableAmount, { shouldValidate: true, shouldDirty: true });
    onChangeStableAmountOrigin(stableAmount);
  }, [availableAmount, cratPrice, onChangeStableAmountOrigin, setValue, stableAmount]);

  const onSubmit = useCallback(
    async (values: FormData) => {
      if (!chain) return;

      try {
        await dispatch(
          crowdsaleModel.thunks.buyTokens({
            stablesAmount: values.stablesAmount,
            fatherAddress: values.fatherAddress || ZERO_ADDRESS,
            stableCoinAddress: STABLECOINS_MAP[currency],
            price: cratPrice,
            chain,
          }),
        ).unwrap();
      } catch (err: any) {
        logger(err);
      }
    },
    [chain, cratPrice, currency, dispatch],
  );

  return useMemo(
    () => ({
      openConnectModal,
      isAuthenticated: authState.isAuthorized,
      receiveAmount,
      userReferral,
      stableAmount,

      form: {
        handleMaxClick,
        onReferralAddressChange,
        handleSubmit: handleSubmit(onSubmit),
        register,
        control,
        isValid,
        isDirty,
        errorMessage,
        errors: {
          amount: amountError,
          referral: referralError,
        },
      },

      currencyField: {
        onCurrencyChange,
        currency,
      },

      validateField: {
        value,
        onChangeStableAmount,
        onChangeStableAmountOrigin,
      },
    }),
    [
      amountError,
      authState.isAuthorized,
      control,
      currency,
      errorMessage,
      handleMaxClick,
      handleSubmit,
      isDirty,
      isValid,
      onChangeStableAmount,
      onChangeStableAmountOrigin,
      onCurrencyChange,
      onReferralAddressChange,
      onSubmit,
      openConnectModal,
      receiveAmount,
      referralError,
      register,
      stableAmount,
      userReferral,
      value,
    ],
  );
};
