import { Box, Button, Stack, StackProps, Typography } from '@mui/material';
import {
  crowdsaleModel,
  IeoCountdown,
  IeoPriceInfoTitle,
  INITIAL_EXCHANGE_OFFERING_END_TIME,
} from 'entities/crowdsale';
import { BORDER_RADIUS_L, COLOR_STROKE_SECONDARY, fromDecimals, useShallowSelector } from 'shared';

import IEOActivateBg from '../../assets/crowdsale_bg.png';
import { getUtcTimestamp } from '../../lib';

const SHOW_COUNTDOWN = INITIAL_EXCHANGE_OFFERING_END_TIME > getUtcTimestamp();

const LP_URL =
  'https://cratd2csmartchain.gitbook.io/cratd2c-blockchain-testnet-and-mainnet-transition/unique-liquidity-pool';

const IeoActivateWrapper = ({ children, sx, ...props }: StackProps) => (
  <Stack
    id="crowdsale"
    position="relative"
    overflow="hidden"
    mt={5}
    py={{ xs: 8, md: 9.25 }}
    border={`1px solid ${COLOR_STROKE_SECONDARY}`}
    borderRadius={BORDER_RADIUS_L}
    p={{ xs: 2, sm: 4 }}
    alignItems="center"
    spacing={4}
    {...props}
    sx={{
      backgroundImage: `url(${IEOActivateBg})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      ...sx,
    }}
  >
    {children}
  </Stack>
);

export const IeoActivate = () => {
  return (
    <IeoActivateWrapper>
      <IeoPriceInfoTitle>
        {SHOW_COUNTDOWN ? (
          <>
            14-Day Window Before New CEX Listings!
            <br />@ $0.29
          </>
        ) : (
          <>
            UNIQUE LIQUIDITY POOL
            <br />
            Buy $CRAT and Get 70% MORE!
          </>
        )}
      </IeoPriceInfoTitle>
      {SHOW_COUNTDOWN ? (
        <IeoCountdown />
      ) : (
        <Button href={LP_URL} target="_blank">
          Buy here
        </Button>
      )}
    </IeoActivateWrapper>
  );
};

export const IeoActivateLimitedTimeDeal = () => {
  const price = useShallowSelector(crowdsaleModel.selectors.getProp('cratPrice'));

  return (
    <IeoActivateWrapper>
      <IeoPriceInfoTitle>
        Limited-Time Deal <br />
        <Typography
          component="span"
          textAlign="center"
          display="block"
          className="primary semi-bold"
          sx={{ fontSize: { xs: 20, sm: 'unset' }, lineHeight: { xs: '24px', sm: 'unset' } }}
          mt={1.25}
        >
          Buy CratD2C-Pre Token for ${fromDecimals(price)} and <br /> receive an equal amount of CRAT Native Coins
          <Box component="br" /> 100% MATCH BONUS!
        </Typography>
      </IeoPriceInfoTitle>
      {SHOW_COUNTDOWN && <IeoCountdown />}
    </IeoActivateWrapper>
  );
};
