export const TABLE_DATA = [
  ['OFFICIAL WEBSITE (ICO)', 'cratd2csmartchain.io'],
  ['STAKING PORTAL', 'staking.cratd2csmartchain.io'],
  ['FAUCET', 'faucet-testnet.cratd2csmartchain.io '],
  ['WIN 200K GIVEAWAY', 'cratd2csmartchain.io/giveaway'],
  ['AIRDROPS', 'cratd2cairdrop.io'],
  ['PRE-ICO', 'cratd2claunch.pro'],
  ['EXPLORER', 'explorer.cratd2csmartchain.io'],
  ['General Inquiries, Technical Support:', 'support@cratd2csmartchain.io'],
];
